<template>
<div id="app">
	<div id="app-grid">
		<div class="header">
			<div class="flex-justify-center">
				<div class="nav-wrapper">
					<img class="nav-logo" src="./assets/ata_logo.svg" alt="ATA Sports Management">
					<div id="left-nav" class="hamburger" @click="toggleNav">
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
		</div>
		<left-nav :expand="expandNav" @toggleNav="toggleNav">
                <div>
                    <div class="flex-justify-between p-20">
                        <div @click="toggleNav">
                            <span style="font-size: 18pt;">&times;</span>
                        </div>
                    </div>
					<a href="#" class="nav-item" @click="toggleNav">Home</a>
					<a href="#about" class="nav-item" @click="toggleNav">About ATA</a>
					<a href="#athletes" class="nav-item" @click="toggleNav">ATA Athletes</a>
					<a href="#contact" class="nav-item" @click="toggleNav">Contact Us</a>
                </div>
        </left-nav>
		<div class="main-content">
				<section class="top-section">
					<div>
						<div class="flex-justify-center">
							<div class="flex-wrap">						
								<img class="logo-lg" src="./assets/ata_logo.svg" alt="ATA Sports">
								<h1 class="text-center text-outline" style="color: white; vertical-align: middle;">ATA Sports <br />Management</h1>						
							</div>
						</div>
					</div>
				</section>
				<section id="about" style="margin-top: 40px;">
					<a class="anchor" id="about" name="about"></a> 
					<hr />
					<div class="flex-justify-center">
						<div>
							<h1>About ATA</h1>
						</div>
					</div>
					<div class="flex-justify-center">
						<div class="contain-section text-center">
							<div class="content-wrapper">
								<h3 class="text-atagreenheader">Mission Statement</h3>
								<span class="mb-25">ATA is a revolutionary sports management company founded on passion, unmatched knowledge and a relentless approach to player development.  Unlike other sports management companies, we don’t ‘hope’ to recruit future pros…we ‘shape’ them!</span>
							</div>
							<div class="content-wrapper">
								<h3 class="text-atagreenheader">The ATA Difference</h3>
								<div ><i>... The Difference is in Development</i></div><br />
								<div class="mb-30">We understand that in order to achieve peak success, an athlete must eliminate all distractions and maintain focus on the ultimate goal. At ATA, we provide our clients access to the very best in video review, on and off ice training, sports psychology and nutrition. Our mission is to be by our client’s side every step of the way, constantly helping them grow as athletes, professionals, and human beings.</div>							
							</div>								
						</div>
					</div>
				</section>
				<a class="anchor" id="athletes" name="athletes"></a> 
				<hr />
				<section id="athletes">
					<div class="flex-justify-center">
						<h1>ATA Athletes</h1>
					</div>
					<div class="flex-justify-center">
						<div class="contain-section">						
							<!-- <gallery :images="images" :index="imageIndex" @close="imageIndex = null" style="background-color: rgba(0,0,0,0.9)"></gallery> -->
							<div class="images">
								<div class="image" v-for="(image, idx) in images" :key="idx" @click="imageIndex = idx" 
									:style="{ backgroundImage: 'url(' + image + ')', width: '350px', height: '350px', backgroundSize: 'cover', backgrounRepeat: 'no-repeat', backgroundPosition: 'center' }">
								</div>
							</div>
						</div>						
					</div>						
				</section>
				<a class="anchor" id="contact" name="contact"></a> 
				<section id="contact" class="content-wrapper">
					<hr />
					<div class="flex-justify-center">
						<h1>Contact Us</h1>
					</div>
					<div class="flex-justify-center">
						<div class="flex-wrap">
							<div class="m-5">							
								<table>
									<tr>
										<td colspan="2">ATA SPORTS MANAGEMENT</td>
									</tr>
									<tr>
										<th><font-awesome-icon :icon="['fas', 'phone']"></font-awesome-icon></th>
										<td>
											<a href="tel:416-708-0691">416-708-0691</a>
										</td>
									</tr>
									<tr>
										<th><font-awesome-icon :icon="['far', 'envelope']"></font-awesome-icon></th>
										<td>
											<a href="mailto:marty@atasportsmanagement.com">marty@atasportsmanagement.com</a>
										</td>
									</tr>
									<tr>
										<th><font-awesome-icon class="map-marker" :icon="['fas', 'map-marker-alt']"></font-awesome-icon></th>
										<td>
											4000 Chesswood Dr.<br />North York, ON <br />M3J 2B9
										</td>
									</tr>
								</table>
							</div>
							<div>
								<iframe width="350"						
										height="350"
										frameborder="0" style="border:0"
										src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCTDZJu7lKuH9VUcHsABH5W2KfuVAKGWpg&q=4000+Chesswood+dr+North+York,ON" allowfullscreen>
								</iframe>
							</div>
						</div>
					</div>
				</section>
		</div>
		<div class="footer p-20 pb-40">
			<div>
				&copy; 2023 ATA Sports Management Ltd.
			</div>
		</div>
	</div>
</div>
</template>

<script>
import leftNav from "./components/left-nav.vue";
// import VueGallery from "vue-gallery";

export default {
	data() {
		return {
			expandNav: false,
			imageIndex: null,
			images: [
				require("./assets/romano1.jpg"),
				require("./assets/romano2.jpg"),
				require("./assets/romano3.jpg"),
				require("./assets/stewart1.jpg"),
			],
			contact: {
				email: null,
				message: null,
			},
		};
	},
	methods: {
		toggleNav(target) {
			if (target != null) {
			}
			this.expandNav = !this.expandNav;
		},
		submitContact(e) {
			console.log(e);
		},
	},
	components: {
		"left-nav": leftNav,
		// gallery: VueGallery,
	},
};
</script>


<style lang="scss" scoped>
@import "./styles/_mixins.scss";

body {
	margin: 0px;
}

* {
	box-sizing: border-box;
}

*,
body {
	color: #f0f0f0;
	font-family: "Segoe UI", Arial, "Verdana";
	background: none;
}

html,
body {
	height: 100%;
	font-size: 12pt;
	margin: 0px;
}

h3 {
	color: #317c2a;
	margin-top: 10px;
	font-weight: 500;
}

.page-content {
	padding-top: 145px;
	padding: 10px;
}

.button {
	padding: 8px 8px;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	outline: none;
	background-color: #6c757d;
	color: white;
}

.flex-justify-center {
	display: flex;
	justify-content: center;
}

.flex-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

#app {
	background-color: #000;
	color: #f0f0f0;
	width: 100%;
	min-height: 100vh;
	display: grid;
}

.app-grid {
	display: grid;
	grid-template-areas: "header header header" "main main main" "footer footer footer";
	grid-template-rows: auto 2fr;
	margin: 0 auto;
}

.header {
	grid-area: header;
	grid-column: 2;
	border-bottom: 2px #317c2a solid;
	background-color: #111;
}

.contain-section {
	max-width: 985px;
}

.nav-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100vw;
	max-width: 980px;
}

.nav-logo {
	width: 3rem;
	background: url("assets/ata_logo.svg") no-repeat;
	margin: 10px;
}

.hamburger {
	margin-top: 20px;
}

#left-nav div {
	width: 25px;
	height: 2px;
	background-color: #e0e0e0;
	margin: 6px 0;
	margin-right: 10px;
}

.nav-item {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 500;
	margin-left: 10px;
	display: block;
	padding: 20px;
	//
}

.main-content {
	grid-area: main;
}

.top-section {
	background-image: url("assets/header.jpg");
	background-size: auto, cover;
	background-position: bottom;
	padding-top: 20vh;
	padding-bottom: 20vh;
}

h1 {
	text-transform: uppercase;
	margin-top: 30px;
	color: #e0e0e0;
	font-size: 2rem;
}

.logo-lg {
	height: 220px;
}

.footer {
	padding-top: 10vh;
	padding-bottom: 10vh;
	grid-area: footer;
	text-align: center;
	background-color: #111;
}

hr {
	max-width: 80px;
	border: 6px solid #317c2a;
	margin-top: 20px;
}

.text-atagreenheader {
	margin-bottom: 10px;
	color: #317c2a;
	text-transform: uppercase;
}

.text-outline {
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.text-center {
	text-align: center;
}

.images {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.image {
	background-color: blue;
	background-color: white;
}

.map-marker path {
	fill: rgb(231, 76, 60);
}

a {
	text-decoration: none;
}

a.anchor {
	display: block;
	position: relative;
	top: -110px;
}

th,
td {
	vertical-align: top;
	padding: 5px;
	font-weight: 500;
}

.dark-overlay {
	background-color: rgba(0, 0, 0, 0.8);
}

.content-wrapper {
	margin-top: 30px;
	margin-bottom: 30px;
	margin-left: 10px;
	margin-right: 10px;
}
</style>