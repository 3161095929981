import { createApp } from "vue";
import App from "./App.vue";
// import router from "./router";
import store from "./store";

// CSS Reset
import "normalize.css"

import { library } from '@fortawesome/fontawesome-svg-core';

import {
	faPhone,
	faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';

import {
	faEnvelope,
} from '@fortawesome/free-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Specify which icons are to be used
library.add(
	faEnvelope,
	faPhone,
	faMapMarkerAlt,
);

// Set icons as custom component


createApp(App).use(store).component('font-awesome-icon', FontAwesomeIcon).mount("#app");
