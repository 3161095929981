<template>
    <div class="sidebar">
        <div class="sidebar-backdrop" v-on:click="$emit('toggleNav')" v-if="expand"></div>
        <transition name="slide">
            <div v-if="expand"
                 class="sidebar-panel">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
	props: {
		expand: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
	transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
	transform: translateX(-100%);
	transition: all 150ms ease-in 0s;
}

.sidebar-backdrop {
	background-color: rgba(0, 0, 0, 0.6);
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	cursor: pointer;
}

.sidebar-panel {
	overflow-y: auto;
	background-color: #111;
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	z-index: 999;
	width: 250px;
}
</style>